// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/root/page/src/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-page-tsx": () => import("/root/page/src/src/templates/Page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-pages-404-tsx": () => import("/root/page/src/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-admin-tsx": () => import("/root/page/src/src/pages/admin.tsx" /* webpackChunkName: "component---src-pages-admin-tsx" */),
  "component---src-pages-callback-tsx": () => import("/root/page/src/src/pages/callback.tsx" /* webpackChunkName: "component---src-pages-callback-tsx" */),
  "component---src-pages-theme-tsx": () => import("/root/page/src/src/pages/theme.tsx" /* webpackChunkName: "component---src-pages-theme-tsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/root/page/src/.cache/data.json")

