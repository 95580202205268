module.exports = [{
      plugin: require('/root/page/src/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"classes":false,"events":false,"google":{"families":["M PLUS 1p","Playfair Display"]}},
    },{
      plugin: require('/root/page/src/node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"themeColor","showSpinner":false},
    },{
      plugin: require('/root/page/src/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/root/page/src/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://aboutme.mingram.net"},
    },{
      plugin: require('/root/page/src/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"runtimeCaching":[{"urlPattern":{},"handler":"cacheFirst"},{"urlPattern":{},"handler":"staleWhileRevalidate"},{"urlPattern":{},"handler":"staleWhileRevalidate"},{"urlPattern":{},"handler":"networkFirst","options":{"cacheName":"rest-api-cache","expiration":{"maxAgeSeconds":2678400},"backgroundSync":{"name":"rest-api-queue","options":{"maxRetentionTime":2678400}}}},{"urlPattern":{},"handler":"networkFirst","options":{"cacheName":"graphql-api-cache","expiration":{"maxAgeSeconds":2678400},"backgroundSync":{"name":"graphql-api-queue","options":{"maxRetentionTime":2678400}}}}]},
    },{
      plugin: require('/root/page/src/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
